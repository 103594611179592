import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  Image,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import contactus from "../assets/images/Contactus.jpg";
import office from "../assets/images/office.png";

const Contact = () => {
  return (
    <Box maxW="100vw" overflowX="hidden" minH="calc(100vh - 184px)">
      <Box
        bgImage={contactus}
        bgSize="cover"
        bgPosition="center"
        h="20.5vh"
        position="relative"
        // style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="white">
            Contact Us
          </Heading>
        </Box>
      </Box>
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5 }}
      >
        <Box mx="auto" w="80vw" py={{ base: "4", md: "8" }}>
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justifyContent="center"
            gap={{ base: "4", lg: "8" }}
            mx={{ base: "auto", lg: "none" }}
            my={10}
            alignItems="center"
          >
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Box
                p="4"
                bg="white"
                rounded="lg"
                boxShadow="lg"
                mb="4"
                borderTop="5px solid #00FFFF"
              >
                <Flex alignItems="center">
                  {" "}
                  {/* Use a Flex container */}
                  <Box mr="4">
                    {" "}
                    {/* Add a new Box for the logo */}
                    <Image
                      src={office} // Replace with the actual URL to the icon image
                      alt="Corporate Icon"
                      maxH="40px" // Adjust the height as needed
                    />
                  </Box>
                  <Heading as="h2" size="lg" color="gray.700">
                    Corporate Office
                  </Heading>
                </Flex>
                <Divider mt="2" />
                <Flex alignItems="center" mt="4">
                  <Icon as={FiMapPin} boxSize={6} color="teal.500" mr="2" />
                  <Text color="gray.600">
                    Office No. 304, 3rd Floor,
                    <br /> 68/72, Ganpat Bhavan,
                    <br /> Dhanji Street, Zaveri Bazar, <br />
                    Mumbai - 400 003, Maharashtra, India
                  </Text>
                </Flex>

                {/* <Flex alignItems="center" mt="2">
                    <Icon as={FiPhone} boxSize={6} color="teal.500" mr="2" />
                    <Text color="gray.600">{phoneNumber}</Text>
                  </Flex>
                  <Flex alignItems="center" mt="2">
                    <Icon as={FiMail} boxSize={6} color="teal.500" mr="2" />
                    <Text color="gray.600">{emailAddress}</Text>
                  </Flex> */}
              </Box>
            </motion.div>

            {/* <Box
              p="4"
              bg="white"
              rounded="lg"
              boxShadow="lg"
              mb="6"
              borderTop="5px solid #00FFFF"
            >
              <Flex alignItems="center">
                {" "}
                <Box mr="4">
                  {" "}
                  <Image src={manufacture} alt="Manufacture Icon" maxH="40px" />
                </Box>
                <Heading as="h2" size="lg" color="gray.700">
                  Manufacturing Unit 1
                </Heading>
              </Flex>
              <Divider mt="2" />
              <Flex alignItems="center" mt="4">
                <Icon as={FiMapPin} boxSize={6} color="teal.500" mr="2" />
                <Text color="gray.600">
                  Unit No. 501, 5th Floor, <br /> 68/72, Ganpat Bhavan, <br />
                  Dhanji Street, Zaveri Bazaar,
                  <br /> Mumbai - 400 003, Maharashtra, India
                </Text>
              </Flex>
            </Box>
            <Box
              p="4"
              bg="white"
              rounded="lg"
              boxShadow="lg"
              mb="6"
              borderTop="5px solid #00FFFF"
            >
              <Flex alignItems="center">
                {" "}
                <Box mr="4">
                  {" "}
                  <Image src={manufacture} alt="Manufacture Icon" maxH="40px" />
                </Box>
                <Heading as="h2" size="lg" color="gray.700">
                  Manufacturing Unit 2
                </Heading>
              </Flex>
              <Divider mt="2" />
              <Flex alignItems="center" mt="4">
                <Icon as={FiMapPin} boxSize={6} color="teal.500" mr="2" />
                <Text color="gray.600">
                  Gala No. 31, 1st Floor,
                  <br /> Apurva Industrial Estate,
                  <br /> Makwana Road, Marol, Andheri East, <br /> Mumbai - 400
                  059, Maharashtra, India
                </Text>
              </Flex>
            </Box> */}
          </Flex>
          {/* <Heading
            as="h1"
            size={{ base: "lg", md: "2xl" }}
            my="4"
            ml="10%"
            color="gray.700"
          >
            Seeking your valuable appointment for a Product review...
          </Heading> */}

          <Box p="4" w="100%" rounded="lg" mb="16">
            <Flex
              justifyContent="space-evenly"
              alignItems="center"
              flexDir={{ base: "column", md: "row" }}
            >
              <Box textAlign="center" mb={{ base: "4", md: "0" }}>
                <Icon as={FiMail} boxSize={6} color="teal.500" mr="2" />
                <Text mt="2" color="gray.600">
                  sankalpdiamond@yahoo.com
                </Text>
              </Box>
              <Box textAlign="center" mb={{ base: "4", md: "0" }}>
                <Icon as={FiPhone} boxSize={6} color="teal.500" mr="2" />
                <Text mt="2" color="gray.600">
                  +91 22 4909 8154
                </Text>
                <Text mt="2" color="gray.600">
                  +91 22 6183 2771
                </Text>
              </Box>
              {/* <Box textAlign="center">
                <Icon as={FiUser} boxSize={6} color="teal.500" mr="2" />
                <Text mt="2" color="gray.600">
                  Mr. Hemant Rathod
                </Text>
              </Box> */}
            </Flex>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Contact;
